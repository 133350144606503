<template>
  <v-container fluid style="background-color:#f2f2f2 !important;min-height:100%;">
    <v-row>
      <v-col cols="12">
        <v-card flat style="border-radius:8px;min-height:320px;">
          <v-card-title
            class="py-2 px-2"
            dark
            style="color:#AD1457;border-bottom: 1px solid #AD1457;"
          >
            <v-btn icon color="#AD1457"
                @click="goBack" class="mr-2">
                <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            Thiết bị đang cho mượn
            <v-spacer />
          </v-card-title>

          <v-card-text class="pa-0 pt-2" style="min-height:300px;">
            <equipment-history-lend queryStatus="LEND" showProperty="true"/>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      token: "token",
    }),
  },
  data() {
    return {
    }
  },
  methods: {
    goBack () {
      this.$router.go(-1)
    },
  }
}
</script>

<style></style>
